@keyframes floatAnimation {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  25% { transform: translateY(-10px) rotate(1deg); }
  75% { transform: translateY(10px) rotate(-1deg); }
}

@keyframes glowingBorder {
  0%, 100% { border-color: rgba(138, 43, 226, 0.8); }
  50% { border-color: rgba(255, 255, 255, 0.8); }
}

.image-card {
    position: relative;
    width: 250px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    animation: floatAnimation 6s ease-in-out infinite;
}

.image-card:hover {
    transform: scale(1.05) translateY(-10px);
    box-shadow: 0 15px 30px rgba(138, 43, 226, 0.3);
    animation: glowingBorder 2s infinite;
}

.image-card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-gallery img {
    width: calc(50% - 10px);
    height: auto;
    border-radius: 8px;
}

.image-card-title {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2em;
    color: #1c56a1;
}

.image-card-title:hover{
    color: #c927d4;
}

.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-enlarged {
    position: relative;
    width: 80%;
    max-width: 800px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff5f5f;
    border: none;
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
}

.enlarged-img {
    width: 100%;
    height: auto;
    max-height: 500px;
    border-radius: 10px;
}

.prev-button, .next-button {
    background: linear-gradient(145deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05));
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255,255,255,0.1);
    padding: 10px 15px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease, color 0.3s ease;
    height: 50px; /* Set fixed height */
    line-height: 30px; /* Ensure the text stays vertically centered */
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.prev-button:hover, .next-button:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #153E75;
    transform: translateY(-50%); /* Ensure hover state keeps translateY the same */
}

.image-description {
    margin-top: 20px;
    color: #c722cd;
}

.image-description h3 {
    margin-bottom: 10px;
}

.image-description p {
    font-size: 1em;
}

@media screen and (max-width: 768px) {
    .image-card {
        width: 200px;
    }

    .image-card:hover {
        transform: scale(1.05);
    }

    .prev-button, .next-button {
        font-size: 1.2rem;
        opacity: 0.5;
        color: #153E75;
    }

    .prev-button:hover, .next-button:hover {
        opacity: 1;
    }
}