.CoCurricular-page {
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  background: #000000;
  margin-left: 270px;
  width: calc(100% - 250px);
  box-sizing: border-box;
  color: #ffffff;
}

.CoCurricular-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

h1 {
  margin-top: 60px; /* Add top margin to create space for hamburger */
  margin-bottom: 30px;
  font-size: 2.5rem;
  max-width: 100%;
  text-align: center;
  color: #e6e6e6;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

p {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #00aaff; /* Changed to a visible shade of blue */
}

@media screen and (max-width: 1024px) {
  .CoCurricular-page {
    margin-left: 0;
    width: 100%;
    padding: 15px;
  }

  .CoCurricular-gallery {
    gap: 15px;
  }

  h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .CoCurricular-page {
    padding: 10px;
  }

  .CoCurricular-gallery {
    gap: 10px;
  }

  h1 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 480px) {
  .CoCurricular-page {
    padding: 5px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .CoCurricular-gallery {
    gap: 5px;
  }
}
