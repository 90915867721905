body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}

.hamburger-menu {
    display: none; /* Hide by default on desktop */
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: rgba(10, 25, 47, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 5px;
}

.hamburger-line {
    width: 100%;
    height: 3px;
    background-color: #153E75; 
    transition: all 0.3s ease;
}

.hamburger-menu.open .hamburger-line {
    background-color: white; 
}

.dropdown-menu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100vh;
    background: rgba(10, 25, 47, 0.95);
    backdrop-filter: blur(10px);
    z-index: 999;
    transition: left 0.3s ease;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-x: hidden; 
}

.dropdown-menu.open {
    left: 0;
}

.dropdown-menu .profile {
    text-align: center;
    margin-bottom: 40px;
}

.dropdown-menu .profile-pic {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    border: 3px solid #543e3e; 
}

.dropdown-menu h2, 
.dropdown-menu p {
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.dropdown-menu nav ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.dropdown-menu nav ul li {
    margin: 20px 0;
}

.dropdown-menu nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    padding: 10px;
    display: block;
    text-align: left;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s ease-in, box-shadow 0.3s ease-in;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.dropdown-menu nav ul li a:hover {
    background-color: #2312c4; 
    color: white;
    font-weight: bolder; 
    box-shadow: 0 0 10px rgba(138, 43, 226, 0.8); /* Glowing effect */
}

.dropdown-menu nav ul li a.hover-effect {
    background-color: #2312c4; /* Simulated hover background */
    color: white;
    font-weight: bolder; 
    box-shadow: 0 0 10px rgba(138, 43, 226, 0.8); /* Glowing effect */
}

@media screen and (max-width: 768px) {
    .dropdown-menu {
        width: 100%; 
        transform: translateX(-100%); 
    }
    
    .dropdown-menu.open {
        transform: translateX(0); 
    }

    .hamburger-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hamburger-line {
        width: 100%;
        height: 2px;
        background: #64ffda;
        transition: all 0.3s ease;
        box-shadow: 0 0 10px rgba(100, 255, 218, 0.5);
    }

    .hamburger-menu.open .hamburger-line:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger-menu.open .hamburger-line:nth-child(2) {
        opacity: 0;
    }

    .hamburger-menu.open .hamburger-line:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }

    .dropdown-menu {
        padding: 2rem;
    }

    .dropdown-menu nav ul li a {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        margin: 10px 0;
        padding: 12px;
        border-radius: 8px;
        font-size: 16px;
        color: #64ffda;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .dropdown-menu nav ul li a:hover {
        background: rgba(100, 255, 218, 0.1);
        transform: translateX(5px);
        box-shadow: 0 0 20px rgba(100, 255, 218, 0.2);
    }
}
