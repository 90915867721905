/* Reset default margins and padding */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.academic-page {
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    padding: 20px;
    margin-left: 270px;
    width: calc(100% - 250px);
    box-sizing: border-box;
    color: #ffffff;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%; /* Changed to 100% for flexbox layout */
    margin: 0 auto; /* Centering the content */
}

/* Added a media query for larger devices */
@media screen and (min-width: 769px) {
    .academic-page {
        margin-left: 270px; /* Margin for larger devices */
        width: calc(100% - 270px); /* Adjust width accordingly */
    }
}

.academic-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.academic-content {
    position: relative;
    z-index: 1;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 20px;
    border: 1px solid rgba(123, 31, 162, 0.2);
}

h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: #e6e6e6;
    text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

p {
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #00aaff;
}

@media screen and (max-width: 768px) {
    .academic-page {
        padding: 15px;
    }

    h1 {
        font-size: 1.75rem;
    }
    
    p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .academic-page {
        padding: 10px;
    }

    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.9rem;
        overflow-wrap: break-word;
    }
}