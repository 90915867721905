@keyframes glow {
  0% { box-shadow: 0 0 10px rgba(138, 43, 226, 0.5); }
  50% { box-shadow: 0 0 30px rgba(138, 43, 226, 0.8); }
  100% { box-shadow: 0 0 10px rgba(138, 43, 226, 0.5); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

body {
  background-color: #000000;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.testimonials-container {
  width: calc(100% - 270px);
  margin-left: 270px;
  padding: 2rem;
  background: linear-gradient(145deg, #000000, #1a1a1a);
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(138, 43, 226, 0.3);
  animation: glow 3s infinite, fadeIn 1s ease-out;
  overflow-y: auto;
  height: calc(100vh - 40px);
  box-sizing: border-box;
}

.testimonials-container h2 {
  text-align: center;
  color: #e6e6e6;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

.testimonials-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.testimonial-card {
  background: linear-gradient(145deg, #1a1a1a, #333333);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 0 15px rgba(138, 43, 226, 0.5);
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-out, float 5s ease-in-out infinite;
  width: 300px;
}

.testimonial-card:hover {
  transform: translateY(-5px) scale(1.03);
  box-shadow: 0 0 25px rgba(138, 43, 226, 0.8);
}

.testimonial-header h3 {
  margin: 0;
  font-size: 1.3rem;
  color: #e6e6e6;
}

.testimonial-header p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #b3b3b3;
}

.testimonial-comment {
  margin: 1rem 0;
  font-size: 1rem;
  color: #cccccc;
  line-height: 1.6;
}

.testimonial-rating {
  display: flex;
  justify-content: flex-end;
}

.star {
  color: #4d4d4d;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.star.filled {
  color: #9b59b6;
  text-shadow: 0 0 10px rgba(155, 89, 182, 0.8);
}

.add-testimonial-btn {
  display: block;
  width: 100%;
  padding: 1rem;
  background: linear-gradient(145deg, #8e44ad, #9b59b6);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px rgba(138, 43, 226, 0.5);
}

.add-testimonial-btn:hover {
  background: linear-gradient(145deg, #9b59b6, #8e44ad);
  transform: translateY(-2px);
  box-shadow: 0 0 25px rgba(138, 43, 226, 0.8);
}

.add-testimonial-btn:active {
  transform: translateY(0);
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.5);
}

@media (max-width: 768px) {
  .testimonials-container {
    width: 100%;
    margin-left: 0;
    padding: 1.5rem;
    height: auto;
  }

  .testimonial-card {
    width: 100%;
    margin-left: 0;
    padding: 1rem;
  }

  .testimonials-grid {
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .testimonials-container {
    width: 100%;
    margin-left: 0;
    padding: 1rem;
  }

  .testimonial-card {
    width: 100%;
    margin-left: 0;
    padding: 0.75rem;
  }

  .add-testimonial-btn {
    width: 100%;
    margin: 0;
    padding: 0.75rem;
  }
}