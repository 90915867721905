@keyframes glow {
  0% { box-shadow: 0 0 5px rgba(138, 43, 226, 0.5); }
  50% { box-shadow: 0 0 20px rgba(138, 43, 226, 0.8); }
  100% { box-shadow: 0 0 5px rgba(138, 43, 226, 0.5); }
}

/* hello  */

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.testimonial-form {
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 0 30px rgba(138, 43, 226, 0.3);
  width: calc(100% - 250px); /* Adjust width to account for sidebar */
  margin: 2rem auto; /* Center the form vertically and add margin */
  animation: fadeIn 0.5s ease-out, glow 3s infinite;
}

.testimonial-form h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #e6e6e6;
  text-align: center;
  font-size: 1.8rem;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #cccccc;
  font-weight: 600;
  transition: color 0.3s ease;
}

.form-group:hover label {
  color: #9b59b6;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  background-color: #2c2c2c;
  border: 1px solid #4d4d4d;
  border-radius: 8px;
  font-size: 1rem;
  color: #e6e6e6;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #8e44ad;
  box-shadow: 0 0 0 2px rgba(142, 68, 173, 0.2), 0 0 10px rgba(138, 43, 226, 0.5);
}

.form-group textarea {
  height: 120px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.form-actions button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px rgba(138, 43, 226, 0.5);
}

.form-actions button[type="submit"] {
  background-color: #8e44ad;
  color: white;
}

.form-actions button[type="submit"]:hover {
  background-color: #9b59b6;
  transform: translateY(-2px);
  box-shadow: 0 0 25px rgba(138, 43, 226, 0.8);
}

/* Media queries for smaller screens */

@media (max-width: 768px) {
  .testimonial-form {
    width: calc(100% - 40px); /* Adjust the form width for smaller screens */
    padding: 1.5rem;
    margin: 1rem auto; /* Adjust the margins for smaller screens */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem; /* Reduce input text size for smaller screens */
  }

  .form-actions {
    flex-direction: column; /* Stack buttons vertically */
    gap: 1rem;
  }

  .form-actions button {
    width: 100%; /* Full-width buttons */
    padding: 0.75rem 1rem; /* Adjust button padding */
  }
}

@media (max-width: 480px) {
  .testimonial-form {
    width: 100%; /* Full width for very small screens */
    padding: 1rem;
  }

  .testimonial-form h3 {
    font-size: 1.5rem; /* Reduce header font size */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.85rem; /* Adjust input font size further */
  }

  .form-actions button {
    padding: 0.5rem 0.75rem; /* Adjust button padding further */
  }
}
