.main-content {
  flex-grow: 1;
  background: linear-gradient(145deg, #000000, #1a1a1a);
  margin-left: 270px;
  width: calc(100% - 270px);
  padding: 40px 20px;
  color: #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins", sans-serif;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
}

.main-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #e6e6e6;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

.main-content p {
  font-size: 1.125rem;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
  color: #9b59b6;
}

.main-content h5 {
  font-size: 1.125rem;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
  color: #8e44ad;
  margin-left: 50px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.card {
  background: linear-gradient(145deg, #1a1a1a, #333333);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 0 15px rgba(138, 43, 226, 0.5);
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px rgba(138, 43, 226, 0.8);
}

.accent-element {
  color: #00adb5;
}

.main-content .card.orange {
  border-color: #ffd700;
  background-color: rgba(255, 215, 0, 0.2);
}

.main-content .card.orange:hover {
  background-color: rgba(255, 215, 0, 0.3);
}

.main-content .card.red {
  border-color: #ff0000;
  background-color: rgba(255, 0, 0, 0.2);
}

.main-content .card.red:hover {
  background-color: rgba(255, 0, 0, 0.3);
}

.main-content .card.green {
  border-color: #00ff00;
  background-color: rgba(0, 255, 0, 0.2);
}

.main-content .card.green:hover {
  background-color: rgba(0, 255, 0, 0.3);
}

.main-content .card.blue {
  border-color: #00bfff;
  background-color: rgba(0, 191, 255, 0.2);
}

.main-content .card.blue:hover {
  background-color: rgba(0, 191, 255, 0.3);
}

.sidebar {
  width: 250px;
  min-height: 100vh;
  background-color: #1a1a1a;
  padding: 20px;
  color: #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 0 15px rgba(138, 43, 226, 0.3);
  font-family: "Poppins", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  max-height: 100vh;
}

.hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
}

.hamburger-line {
  width: 100%;
  height: 3px;
  background-color: #e6e6e6;
  transition: all 0.3s ease;
}

.hamburger-menu.open .hamburger-line {
  background-color: #9b59b6;
}

.dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  min-height: 100vh;
  background-color: #1a1a1a;
  box-shadow: 2px 0 15px rgba(138, 43, 226, 0.3);
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  overflow-y: auto;
}

.dropdown-menu.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 20px;
    max-height: 100vh;
    overflow-y: auto;
  }

  .main-content h1 {
    font-size: 2rem;
    margin: 20px 0;
  }

  .main-content p {
    font-size: 1rem;
  }

  .cards {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .sidebar.active {
    transform: translateX(0);
  }
}

@media (max-width: 480px) {
  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 15px;
  }

  .main-content h1 {
    font-size: 1.8rem;
  }

  .main-content p,
  .main-content h5 {
    font-size: 0.9rem;
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 60px;
    justify-items: center;
  }

  .card {
    width: 100%;
    max-width: 300px;
  }
}