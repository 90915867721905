.about-page {
  position: relative;
  background: linear-gradient(135deg, #000000, #1a1a1a);
  padding: 20px;
  margin-left: 270px;
  overflow-y: auto;
  max-height: 100vh;
  box-sizing: border-box;
  color: #ffffff;
  width: calc(100% - 250px);
  perspective: 1000px;
}

.page-content {
  position: relative;
  max-width: 90%;
  margin: 20px auto;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 30px rgba(138, 43, 226, 0.3);
  animation: glow 3s infinite, fadeIn 1s ease-out;
  backdrop-filter: blur(10px);
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
}

.page-content:hover {
  transform: translateZ(20px);
}

h1,
h2 {
  color: #e6e6e6;
  text-align: center;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

section {
  margin-bottom: 20px;
}

a {
  color: #9b59b6;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #8e44ad;
  text-decoration: underline;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skills ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-item {
  flex: 1 1 calc(50% - 10px);
  font-size: 1.2rem;
  background: linear-gradient(145deg, rgba(142, 68, 173, 0.2), rgba(142, 68, 173, 0.1));
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.skill-item:hover {
  background: linear-gradient(145deg, rgba(142, 68, 173, 0.3), rgba(142, 68, 173, 0.2));
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 10px 20px rgba(138, 43, 226, 0.3);
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #9b59b6;
  object-fit: cover;
  transition: transform 0.3s ease;
  box-shadow: 0 0 15px rgba(138, 43, 226, 0.5);
}

.profile-image:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1024px) {
  .about-page {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .about-page {
    padding: 15px;
    
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  .skill-item {
    flex: 1 1 100%;
    font-size: 1rem;
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 480px) {
  .about-page {
    padding: 10px;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }
}
