.technical-page {
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    padding: 20px;
    margin-left: 270px;
    width: calc(100% - 250px);
    box-sizing: border-box;
    color: #ffffff;
    background: transparent;
}

.technical-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.technical-page h1 {
    margin-bottom: 30px;
    font-size: 2.5rem;
    max-width: 100%;
    color: #e6e6e6;
    text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

p {
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #00aaff !important; /* Changed to a visible shade of blue */
}

.technical-page h2,
.technical-page p {
    justify-content: center;
    text-align: center;
    color: #e6e6e6;
}

.technical-content {
    position: relative;
    z-index: 1;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 20px;
    border: 1px solid rgba(123, 31, 162, 0.2);
}

@media screen and (max-width: 1024px) {
    .technical-page {
        margin-left: 0;
        width: 100%;
    }

    .technical-page h1 {
        font-size: 2rem;
    }

    .technical-gallery {
        gap: 15px;
    }
}

@media screen and (max-width: 768px) {
    .technical-page {
        padding: 15px;
    }

    .technical-page h1 {
        font-size: 1.8rem;
    }

    .technical-gallery {
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 480px) {
    .technical-page {
        padding: 10px;
    }

    .technical-page h1 {
        font-size: 1.6rem;
    }

    .technical-gallery {
        gap: 5px;
    }
}