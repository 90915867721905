@keyframes matrix-rain {
  0% { background-position: 0% 0%; }
  100% { background-position: 0% 100%; }
}

@keyframes cyber-grid {
  0% { transform: perspective(1000px) rotateX(60deg) translateZ(0); }
  100% { transform: perspective(1000px) rotateX(60deg) translateZ(100px); }
}

@keyframes neon-pulse {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.7; }
}

.technical-page,
.academic-page,
.CoCurricular-page {
  position: relative;
  z-index: 1;
}

.technical-page::before,
.academic-page::before,
.CoCurricular-page::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  background: linear-gradient(135deg, #000000, #1a1a1a);
}

.technical-background,
.academic-background,
.CoCurricular-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

.technical-matrix,
.academic-matrix,
.CoCurricular-matrix {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 255, 0, 0.03) 0%,
    rgba(0, 255, 0, 0.05) 50%,
    rgba(0, 255, 0, 0.03) 100%
  );
  background-size: 100% 50px;
  animation: matrix-rain 20s linear infinite;
}

.technical-grid,
.academic-grid,
.CoCurricular-grid {
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background: linear-gradient(90deg, rgba(33, 150, 243, 0.1) 1px, transparent 1px),
              linear-gradient(0deg, rgba(33, 150, 243, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  transform: perspective(1000px) rotateX(60deg);
  animation: cyber-grid 20s linear infinite;
}

.neon-circles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.neon-circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, 
    rgba(123, 31, 162, 0.5) 0%,
    rgba(123, 31, 162, 0.2) 50%,
    transparent 70%
  );
  animation: neon-pulse 5s infinite;
}

.neon-circle:nth-child(1) {
  width: 300px;
  height: 300px;
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}

.neon-circle:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 50%;
  right: 15%;
  animation-delay: 1s;
}

.neon-circle:nth-child(3) {
  width: 250px;
  height: 250px;
  bottom: 10%;
  left: 30%;
  animation-delay: 2s;
}
