html{
  scroll-behavior: smooth;
}
/* Keyframes for Glow and Fade-in effects */
@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(138, 43, 226, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(138, 43, 226, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(138, 43, 226, 0.5);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  background-color: #000000;
  color: #ffffff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Contact Page Styling */
.contact-wrapper {
  position: relative;
  min-height: 100vh;
  width: calc(100% - 250px);
  margin-left: 250px;
  overflow-y: auto;
  padding: 2rem;
}

.contact-page {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(10, 25, 47, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.form-group {
  margin-bottom: 2rem;
}

textarea {
  min-height: 150px;
  resize: vertical;
}

button[type="submit"] {
  margin-bottom: 2rem;
}

/* Sidebar Styling - Complementary color
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #00695c; 
  padding: 20px;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 0 15px rgba(26, 26, 26, 0.3);
  font-family: 'Poppins', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
} */

/* Profile section in Sidebar */
.profile {
  text-align: center;
  margin-bottom: 40px;
}

.profile-pic {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  border: 3px solid #82b1ff; /* Light Blue for contrast */
  transition: all 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(138, 43, 226, 0.8);
}

/* Sidebar heading */
.sidebar h2 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

/* Sidebar text */
.sidebar p {
  color: #ffffff;
  font-size: 14px;
}

/* Sidebar navigation */
nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

nav ul li {
  margin: 20px 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  display: block;
  text-align: left;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

nav ul li a:hover {
  background-color: #ba31b4;
  color: #ffffff;
}

/* Responsive Design Adjustments */
@media screen and (max-width: 1024px) {
  .contact-page {
    margin-left: 0;
    width: 100%;
    padding: 1rem;
    height: auto;
    min-height: calc(100vh - 2rem);
  }
}

@media screen and (max-width: 768px) {
  .contact-wrapper {
    width: 100%;
    margin-left: 0;
  }
  
  .contact-page {
    padding: 1rem;
  }

  .contact-page h1 {
    font-size: 1.8rem;
  }

  .contact-page form {
    max-width: 100%;
  }

  .contact-page .form-group input,
  .contact-page .form-group textarea {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-page {
    padding: 0.5rem;
  }

  .contact-page h1 {
    font-size: 1.6rem;
  }
}
/* Make the main content scrollable */
.main-content {
  overflow-y: auto;
  max-height: 100vh; /* Ensure the height doesn't exceed the viewport */
  padding: 20px; /* Add padding to avoid cutting content */
}

/* Ensure form elements do not get cut off */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; /* Adjust spacing */
  padding: 10px; /* Provide space around elements */
}

input, textarea {
  width: 100%; /* Allow inputs to expand fully */
  max-width: 100%;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}
