::-webkit-scrollbar {
    display: none;
}

@keyframes cyberpunkGlow {
  0% { box-shadow: 0 0 20px #ff00ff, 0 0 40px #00ffff; }
  50% { box-shadow: 0 0 40px #00ffff, 0 0 60px #ff00ff; }
  100% { box-shadow: 0 0 20px #ff00ff, 0 0 40px #00ffff; }
}

@keyframes gradientFlow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100vh;
    background: rgba(10, 25, 47, 0.9);
    backdrop-filter: blur(10px);
    padding: 2rem;
    z-index: 100;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    #ff00ff 0%,
    #00ffff 25%,
    #ff00ff 50%,
    #00ffff 75%,
    #ff00ff 100%
  );
  background-size: 400% 400%;
  opacity: 0.1;
  animation: gradientFlow 15s ease infinite;
  z-index: -1;
}

.profile {
    text-align: center;
    margin-bottom: 40px;
}

.profile-pic {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #ff00ff, #00ffff) border-box;
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);

  animation: cyberpunkGlow 4s ease-in-out infinite;
  transition: all 0.3s ease;
}

.profile-pic:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(138, 43, 226, 0.8);
}

h2 {
    font-size: 20px;
    color: #ffffff;
    margin: 10px 0;
    text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

p {
    color: #ffffff !important;
    font-size: 14px;
}

nav ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

nav ul li {
    margin: 20px 0;
}

nav ul li a {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  display: block;
  text-align: left;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

nav ul li a::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
}

nav ul li a:hover::before {
    left: 100%;
}

nav ul li a:hover {
    background-color: #2312c4 !important;
    color: white;
    font-weight: bolder;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transform: translateX(5px);
}

button {
    background-color: #2312c4;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease;
}

button:hover {
    background-color: #5531ba;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(186, 49, 180, 0.4);
}

.profile h2, .profile p {
    color: white !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    text-align: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
}
